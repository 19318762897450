// MUST be the first import
import './instrument';
import '@/configs/zod-setup';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ErrorBoundary } from '@/components/error-boundaries/error-boundary';
import { Toaster } from '@/components/ui/toast';

import { router } from '@/configs/router';

import { ReloadPrompt } from '@/features/pwa';

import './index.css';

const fullScreenError = (
  <div className='flex h-screen'>
    <div className='m-auto text-center'>Something went wrong</div>
  </div>
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={fullScreenError}>
      <RouterProvider router={router} />
      <Toaster />
    </ErrorBoundary>
    {/*
      Separate ErrorBoundary for app updater so that it is possible to update app when errors are encountered in other parts
      Useful when pushing updates to fix these other errors
      */}
    <ErrorBoundary fallback={fullScreenError}>
      <ReloadPrompt />
    </ErrorBoundary>
  </React.StrictMode>,
);
