// original source: https://github.com/colinhacks/zod/blob/master/src/locales/en.ts

import {
  z,
  ZodIssueCode,
  ZodParsedType,
  defaultErrorMap,
  type ZodErrorMap,
} from 'zod';

const errorMap: ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        return { message: 'This field is required' };
      }
      if (issue.received === ZodParsedType.null) {
        return { message: 'This field is required' };
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'string') {
        if (issue.minimum === 1) {
          return { message: 'This field is required' };
        }
      }
      break;
    case ZodIssueCode.invalid_enum_value:
      return { message: 'Invalid value. Please select a valid option.' };
    default:
      return defaultErrorMap(issue, ctx);
  }
  return defaultErrorMap(issue, ctx);
};

z.setErrorMap(errorMap);
