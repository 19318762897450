import { createPathGenerator } from './utils';

export const pathToTechnicianSection = createPathGenerator('/tech');

export const pathToTechAppointments =
  pathToTechnicianSection.createChildPath('appointments');
export const pathToTechAppointmentNew =
  pathToTechAppointments.createChildPath('new');
export const pathToTechAppointmentNewCustomer =
  pathToTechAppointments.createChildPath('customers/new');
export const pathToTechAppointmentEditCustomer =
  pathToTechAppointments.createChildPath('customers/edit/:id');
export const pathToTechAppointment =
  pathToTechAppointments.createChildPath(':appointmentId');
export const pathToTechAppointmentPreview =
  pathToTechAppointment.createChildPath('preview');

export const pathToTechAppointmentReports =
  pathToTechAppointment.createChildPath('reports');
export const pathToTechAppointmentReportsSelect =
  pathToTechAppointmentReports.createChildPath('select');

export const pathToTechReport =
  pathToTechAppointmentReports.createChildPath(':reportId');
export const pathToTechReportFill = pathToTechReport.createChildPath('fill');
export const pathToTechReportDeficiencies =
  pathToTechReport.createChildPath('deficiencies');

export const isTechnicianPath = (path: string) =>
  path.startsWith(pathToTechnicianSection.template);
