import { type NonIndexRouteObject } from 'react-router-dom';

export const appProviders =
  ({
    withOfflineExchange,
    withRxDb,
    allowAnonymousAccess,
  }: {
    withOfflineExchange?: boolean;
    withRxDb?: boolean;
    allowAnonymousAccess?: boolean;
    getLayoutRenderer?: () => Promise<() => JSX.Element>;
  }): NonNullable<NonIndexRouteObject['lazy']> =>
  async () => {
    const { AppProvider } = await import('@/configs/providers/app');

    return {
      Component: () => (
        <AppProvider
          withOfflineExchange={!!withOfflineExchange}
          withRxDb={!!withRxDb}
          allowAnonymousAccess={!!allowAnonymousAccess}
        />
      ),
    };
  };

export const publicProviders =
  (): NonNullable<NonIndexRouteObject['lazy']> => async () => {
    const { PublicProvider } = await import('@/configs/providers/public');

    return {
      Component: () => <PublicProvider />,
    };
  };

export const landingProviders =
  (): NonNullable<NonIndexRouteObject['lazy']> => async () => {
    const { LandingProvider } = await import('@/configs/providers/landing');

    return {
      Component: () => <LandingProvider />,
    };
  };
