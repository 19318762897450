import { createPathGenerator } from './utils';

export const pathToAuth = createPathGenerator('/auth');

export const pathToAuthRedirect = pathToAuth.createChildPath('callback');

export const pathToAuthTceCallback = pathToAuth.createChildPath('tce/callback');

export const pathToAuthQuickbooksCallback = pathToAuth.createChildPath(
  'quickbooks/callback',
);
