import { type LucideIcon } from 'lucide-react';

import coolicons from './coolicons';
import {
  MenuCollapse,
  InverseXCircle,
  RefreshCcwSpinning,
  ReceiptTax,
} from './custom-icons';

// Coolicons used as-is
export const {
  AddPlus,
  Archive,
  ArrowLeftMd,
  ArrowsReload01,
  ArrowReload02,
  ArrowUndoUpLeft,
  ArrowUndoUpRight,
  Bold,
  Building04,
  Calendar,
  Camera,
  Check,
  ChevronRightMd,
  Circle,
  Clock,
  CloseMd,
  CloudUpload,
  Tablet,
  Download,
  EditPencilLine01,
  EditPencil01,
  ExternalLink,
  FileCheck,
  FileUpload,
  Font,
  HamburgerMd,
  Handbag,
  HeadingH1,
  HeadingH2,
  HeadingH3,
  Image02,
  Italic,
  Link,
  LinkHorizontal,
  LinkHorizontalOff,
  ListOrdered,
  ListUnordered,
  ListAdd,
  LogOut,
  Lock,
  Mail,
  Mobile,
  MoreHorizontal,
  MoreVertical,
  Note,
  DoubleQuotesL,
  PaperPlane,
  Redo,
  RemoveMinus,
  SearchMagnifyingGlass,
  Square,
  SquareCheck,
  Star,
  Strikethrough,
  Table,
  Text,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TrashFull,
  Underline,
  Users,
  UserSquare,
  WifiOff,
} = coolicons;

// Custom icons
export { CircleCheck } from './lib/circle-check';
export { CircleWarning } from './lib/circle-warning';
export { TriangleWarning } from './lib/triangle-warning';
export { Printer } from './lib/printer';
export { Phone } from './lib/phone';
export { Settings } from './lib/settings';
export { FileDocument } from './lib/file-document';
export { MapPin } from './lib/map-pin';
export { Info } from './lib/info';
export { MenuCollapse, InverseXCircle, RefreshCcwSpinning, ReceiptTax };

// File types
export { UnknownFile } from './file-types/unknown';
export { GenericFile } from './file-types/generic';
export { ZipFile } from './file-types/zip';
export { PdfFile } from './file-types/pdf';
export { WordFile } from './file-types/word';
export { ImageFile } from './file-types/image';
export { TextFile } from './file-types/text';
export { ExcelFile } from './file-types/excel';
export { AudioFile } from './file-types/audio';
export { FontFile } from './file-types/font';
export { PresentationFile } from './file-types/presentation';
export { VideoFile } from './file-types/video';
export { ScriptFile } from './file-types/script';

//
//
//
// You should not add new Lucide icons, look for icon SVGs in Figma
// https://www.figma.com/file/JOswcsJhl8Tl6JbQm3T0PZ/Styles?type=design&node-id=24%3A17319&mode=design&t=Vo67R9Fq1tQ0mveq-1
//
//
//
export {
  AlertCircle,
  AlertTriangle,
  AlignLeft,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  LogIn,
  UserCircle,
  XCircle,
  AlertTriangleIcon,
  ListFilter,
  Eye,
  Bolt,
  ListTodo,
  Superscript,
  Subscript,
  RemoveFormatting,
  SeparatorHorizontal,
  Columns3,
  Scissors,
  InfoIcon,
  Copy,
} from 'lucide-react';

export type Icon =
  | LucideIcon
  | typeof MenuCollapse
  | typeof InverseXCircle
  | typeof RefreshCcwSpinning
  | typeof ReceiptTax;
